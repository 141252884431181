if (module.hot) {
  module.hot.accept();
}

import 'slick-carousel';
// import 'jquery-mousewheel';
// import 'malihu-custom-scrollbar-plugin';

// *****************************************************************
// WOW.js - workaround fix
// *****************************************************************
const WOW = require('wowjs');
window.wow = new WOW.WOW();
window.wow.init();
// *****************************************************************

$(document).ready(function () {
  // $(".make-business__description").mCustomScrollbar({
  //   theme: "dark",
  // });
  $('.banner').slick({
    prevArrow: "<button type='button' class='slick-prev slick-arrow'></button>",
    nextArrow: "<button type='button' class='slick-next slick-arrow'></button>",
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    speed: 400,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
        }
      }
    ]
  });
  $('.our-clients-carousel').slick({
    slidesToShow: 5,
    // variableWidth: true,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    arrows: false,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  });
  // $('.news-slider').slick({
  //   prevArrow: "<button type='button' class='slick-prev slick-arrow'></button>",
  //   nextArrow: "<button type='button' class='slick-next slick-arrow'></button>",
  //   slidesToShow: 2,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1366, 
  //       settings: {
  //         arrows: false,
  //       }
  //     },
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         slidesToShow: 1,
  //         arrows: false,
  //       }
  //     }
  //   ]
  // });

  $("#frmContact").on('submit',(function(e){ 
    e.preventDefault(); 
    $("#mail-status").hide(); 
    $('#send-message').hide(); 
    $('#loader-icon').show(); 
    $.ajax({ 
     url: "contact.php", 
     type: "POST", 
     dataType:'json', 
     data: { 
     "name":$('input[name="name"]').val(), 
     "email":$('input[name="email"]').val(), 
     "phone":$('input[name="phone"]').val(),
     "g-recaptcha-response":$('textarea[id="g-recaptcha-response"]').val()},     
     success: function(response){
      $('.modal').removeClass('active');
      $('input').each(function(){
        $(this).val('');
      })
      //$("#frmContact").reset();
     if(response.type == "error") { 
      // $('#send-message').show(); 
      // $("#mail-status").attr("class","error");     
     } else if(response.type == "message"){ 
      // $('#send-message').hide(); 
      // $("#mail-status").attr("class","success");        
     } 
    //  $("#mail-status").html(response.text);  
     }, 
     error: function(){}  
    }); 
   }));
});

// ====================   global variables   ====================

let body = document.body;

// ====================   mobile-menu   ====================

let mobMenu = document.querySelector('.menu-mob'),
    hamburgerMenu = document.querySelector('.hamburger-menu');

    hamburgerMenu.addEventListener('click', ()=> {
      mobMenu.classList.toggle('active');
      hamburgerMenu.classList.toggle('active');
      body.classList.toggle('overflow');
    });

    // ====================   modal   ====================

    let modal = document.querySelector('.modal'),
        buttonModalClose = document.querySelector('.modal__close'),
        buttonConsultation = document.querySelectorAll('.button_consultation'),
        modalBg = document.querySelector('.modal__bg');

        buttonConsultation.forEach(item => {
          item.addEventListener('click', ()=> {
            modal.classList.add('active');
            body.classList.add('overflow');
          })
        });

        buttonModalClose.addEventListener('click', ()=> {
          modal.classList.remove('active');
          body.classList.remove('overflow');
        })

        modalBg.addEventListener('click', ()=> {
          modal.classList.remove('active');
          body.classList.remove('overflow');
        })

// ====================   header   ====================
    
let header = document.querySelector('.header__desk');

window.onscroll = function() {
  
  let headerHeight = header.offsetHeight;
  let headerUpper = document.querySelector('.header__upper').offsetHeight;
  

  let html = document.documentElement;
  let body = document.body;

  let scrollTop = html.scrollTop || body && body.scrollTop || 0;
  scrollTop -= html.clientTop; // в IE7- <html> смещён относительно (0,0)


  if ( scrollTop >=  headerUpper) {
    header.classList.add('header_sticky');

  } else {
    header.classList.remove('header_sticky');
  }
};





// import 'jquery-mask-plugin';
// import 'webpack-jquery-ui/css';
// import 'webpack-jquery-ui/selectmenu';




jQuery(function($){
$('a[href*="#"]').on('click.smoothscroll', function( e ){
      mobMenu.classList.remove('active');
      hamburgerMenu.classList.remove('active');
      body.classList.remove('overflow');
var hash    = this.hash, _hash   = hash.replace(/#/,''), theHref = $(this).attr('href').replace(/#.*/, '');
if( theHref && location.href.replace(/#.*/,'') != theHref ) return;
var $target = _hash === '' ? $('body') : $( hash + ', a[name="'+ _hash +'"]').first();
if( ! $target.length ) return;
e.preventDefault();
$('html, body').stop().animate({ scrollTop: $target.offset().top - 0 }, 400, 'swing', function(){
window.location.hash = hash;
});
});
});


